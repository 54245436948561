<template>
  <div class="card mb-3 shadow-sm">
    <div class="card-body">
      <ul class="nav nav-pills" id="pills-tab" role="tablist">
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <router-link
            to="/showcase-management"
            v-bind:class="['nav-link', !activeTab ? 'active' : '']"
            data-toggle="pill"
            role="tab"
            aria-controls="site-setting"
            aria-selected="true"
          >
            <span class="ml-1">Ana Sayfa</span>
          </router-link>
        </li>
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <router-link
            to="/showcase-management/category"
            v-bind:class="['nav-link', activeTab == 'category' ? 'active' : '']"
            data-toggle="pill"
            role="tab"
            aria-controls="site-setting"
            aria-selected="true"
          >
            <span class="ml-1">Kategori</span>
          </router-link>
        </li>
        <li
          v-if="getTabs && getTabs.length"
          class="col-12 p-0 nav-item mb-2"
          role="presentation"
        >
          <hr class="mt-2 mb-1" />
        </li>
        <transition-group name="block" type="transition">
          <li
            v-for="tab in getTabs"
            v-bind:key="tab.id"
            class="col-12 p-0 nav-item mb-2"
            role="presentation"
          >
            <router-link
              v-bind:to="'/showcase-management/' + tab.id"
              v-bind:class="['nav-link', activeTab == tab.id ? 'active' : '']"
              data-toggle="pill"
              role="tab"
              aria-controls="site-setting"
              aria-selected="true"
            >
              <span class="ml-1">{{ tab.title }}</span>
            </router-link>
          </li>
        </transition-group>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Navigation",
  props: {
    tabs: [],
    activeTab: null,
  },
  computed: {
    getTabs() {
      return (
        this.tabs &&
        this.tabs.filter(
          (tab) =>
            tab.title &&
            tab.type !== "category-detail" &&
            tab.hasOwnProperty("id")
        )
      );
    },
  },
};
</script>
