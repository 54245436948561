<template>
  <WeContainer card="" v-if="ready && categoryDetail">
    <div class="row">
      <div class="col-12 col-lg-2 sticky-tabs">
        <Navigation v-bind:tabs="tabs" activeTab="category" />
      </div>
      <div class="col-12 col-lg">
        <WeCard class="sticky-top shadow-sm mb-3">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="mb-0 font-weight-bold">{{ categoryDetail.title }}</h6>
            </div>
            <div class="col-auto">
              <WeSubmitButton
                v-bind:submit-status="submit"
                v-on:submit="onSubmit"
              />
            </div>
          </div>
        </WeCard>

        <WeCard>
          <WeSearchInput
            class="mb-0 product-select"
            name="categoryProduct"
            route="categoryProduct"
            v-bind:additional="categoryDetail.category_id"
            v-bind:placeholder="$t('searchProduct')"
            v-on:on-select="onSelectProduct"
          />
          <draggable v-model="categoryDetail.products">
            <div
              v-for="(product, index) in categoryDetail.products"
              :key="index"
              class="product-card"
            >
              <i class="fa fa-arrows-alt" />
              <img v-bind:src="product.image" />
              <div class="product-info">
                <span class="product-info-name">{{ product.name }}</span>
                <div class="product-info-wrapper">
                  <span class="product-info-quantity"
                    ><b>Miktar:</b> {{ product.quantity }}</span
                  >
                  <span class="indicator">|</span>
                  <span class="product-info-price"
                    ><b>Fiyat:</b> {{ product.price }}</span
                  >
                  <template
                    v-if="product.discountPrice && product.discountPrice > 0"
                  >
                    <span class="indicator">|</span>
                    <span class="product-info-discount"
                      ><b>İndirimli Fiyat:</b> {{ product.discountPrice }}</span
                    >
                  </template>
                  <template v-if="product.quantity < 1">
                    <span class="indicator">|</span>
                    <small class="font-12 text-danger"
                      ><b
                        >Ürün stokta bulunmadığı için vitrinde gösterilmez</b
                      ></small
                    >
                  </template>
                </div>
              </div>
              <a
                class="btn ml-3 product-trash"
                v-on:click="removeProduct(index)"
              >
                <i class="fa fa-trash mr-2" />
              </a>
            </div>
          </draggable>
        </WeCard>
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions } from "vuex";
import draggable from "vuedraggable";
import Navigation from "../../../Navigation/Index.vue";
export default {
  name: "CategoryDetail",
  components: {
    draggable,
    Navigation,
  },
  data() {
    return {
      ready: false,
      submit: false,
      categoryDetail: {},
      tabs: [],
    };
  },
  methods: {
    ...mapActions("showcaseManagement", [
      "getHomeBlocks",
      "getCategoryBlocks",
      "saveCategoryProducts",
    ]),
    onSubmit() {
      this.submit = true;
      this.saveCategoryProducts({
        form: this.categoryDetail,
        onSuccess: (result) => {
          if (result.data && result.data.status !== "error") {
            this.$toast.success("Kategori Detay Güncellendi");
          } else {
            this.$toast.error("Kategori Detay Güncellenemedi");
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    onSelectProduct(data) {
      const products = this.categoryDetail.products;
      const checkProduct = products.findIndex((p) => p.id == data.id);
      if (checkProduct >= 0) {
        this.$toast.info("Bu ürün listede mevcut");
      } else if (data.quantity < 1) {
        this.$toast.info("Stokta olmayan ürün eklenemez");
      } else {
        products.push({
          name: data.name,
          image: data.image,
          id: data.id,
          price: localization.getMoneyFormat(
            data.currency_code.code,
            data.price
          ),
          discountPrice: localization.getMoneyFormat(
            data.currency_code.code,
            data.discount_price
          ),
          quantity: data.quantity + " " + data.quantity_type,
        });
      }
    },
    removeProduct(index) {
      this.$swal({
        title: "Ürün Sıralamadan Kaldırılacak",
        text: "İşlemi onaylıyor musunuz ?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.categoryDetail.products.splice(index, 1);
        }
      });
    },
  },
  mounted() {
    this.getHomeBlocks({
      onSuccess: (result) => {
        this.tabs = result?.data.items;
        this.getCategoryBlocks({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.categoryDetail = result?.data.item;
            if (this.categoryDetail && this.categoryDetail.products) {
              this.categoryDetail.products = this.categoryDetail.products.map(
                (item) => {
                  item.price = localization.getMoneyFormat(
                    item.currency_code.code,
                    item.price
                  );
                  item.discountPrice = localization.getMoneyFormat(
                    item.currency_code.code,
                    item.discount_price
                  );

                  return item;
                }
              );
            }
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      },
    });
  },
};
</script>
<style lang="scss">
.categoryModal .custom-card-title {
  display: none;
}
.categoryModal .card {
  border: none;
}
.categoryModal .modal-body {
  padding: 0;
}
.categoryModal .card-body {
  padding: 0;
}
.sticky-tabs {
  position: sticky;
  top: -2px;
  height: max-content;
  margin-bottom: -16px;
}
.sticky-header {
  top: -2px;
  position: sticky;
  z-index: 1;
}
.sticky-header-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.sticky-header-content > span {
  font-size: 17px;
  font-weight: 600;
  width: 100%;
}
.sticky-header-content button {
  flex-shrink: 0;
}
.product-card {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: grab;
  margin-top: 16px;
  padding-left: 32px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.product-card:hover {
  background-color: #fbfbfb;
}
.product-card > i {
  color: #3f51b5;
  margin-right: 24px;
  font-size: 16px;
}
.product-card img {
  width: 56px;
  height: 56px;
}
.product-info {
  width: 100%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.product-info-wrapper {
  display: flex;
  gap: 10px;
  align-content: center;
  align-items: center;
  margin-top: 4px;
}

.product-info-name {
  font-size: 16px;
  font-weight: 500;
}

.product-info-quantity,
.product-info-price,
.product-info-discount {
  font-size: 14px;
  font-weight: 500;
  color: #697481;
}

.sticky-content {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}
.product-select input {
  border: 1px solid #80bdff !important;
}
.product-trash {
  background: #fef5f7;
  color: #dc3545;
}

.product-trash:hover {
  background: #dc3545;
  color: #fff;
}

.product-trash i {
  margin: 0 !important;
}

.indicator {
  color: #d3d3d3;
  padding-bottom: 1px;
}
</style>